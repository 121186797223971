























import AppDialog from "@/components/Navigation/AppDialog.vue";
import { CaptureEvent, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import UserMembership from "@/views/profile/UserMembership.vue";
import { BaseUser, UpdateUserSubscriptionRequest } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import RefreshIcon from "@/assets/icons/saxcons/refresh-circle-linear.svg";
import { WatchLoading } from "@/decorators/Loading";
import { Functions } from "@/firebase";
import { httpsCallable } from "@firebase/functions";

@Component({
  name: "selected-user-membership",
  components: {
    AppDialog,
    UserMembership,
    RefreshIcon
  }
})
export default class SelectedUserMembership extends mixins(
  EmitClose,
  CaptureEvent,
  LoadingMixin
) {
  get selectedUser(): BaseUser | null {
    return (
      this.$store.getters.users.find((u) => u.uid === this.$route.params.id) ||
      null
    );
  }

  @WatchLoading()
  async fetchLatestSubscription() {
    if (
      this.selectedUser &&
      this.selectedUser.stripeCustomerId &&
      this.$store.getters.merchantInfo?.merchantId
    ) {
      const payload: UpdateUserSubscriptionRequest = {
        uid: this.selectedUser.uid,
        accountId: this.$store.getters.merchantInfo.merchantId,
        customerId: this.selectedUser.stripeCustomerId
      };
      const { data: response } = await httpsCallable<
        UpdateUserSubscriptionRequest,
        { isSuccess: boolean }
      >(
        Functions,
        "updateUserSubscriptions"
      )(payload);
      if (response.isSuccess) {
        await this.$store.dispatch("getUsersById", this.selectedUser.uid);
        window.location.reload();
      }
    }
    this.isEventCaptured = false;
  }
}
