




































































import { WatchLoading } from "@/decorators/Loading";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import {
  Subscription,
  Membership,
  BaseUser,
  AppUser
} from "@sportango/backend";
import { Prop } from "vue-property-decorator";
import {
  StripeCardPaymentMethod,
  StripeBankPaymentMethod
} from "@/types/Payment";
import { StripeClient } from "@/mixins/Stripe";

@Component({
  name: "user-membership",
  components: {
    LottieAnimation
  }
})
export default class UserMembership extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  StripeClient
) {
  @Prop({ required: false, default: null })
  selectedUser!: BaseUser | null;

  cards: Array<StripeCardPaymentMethod> = [];
  bankAccounts: Array<StripeBankPaymentMethod> = [];
  selectedMembership: Membership | null = null;

  get user(): BaseUser | AppUser | null {
    if (this.selectedUser) {
      return this.selectedUser;
    } else if (this.currentUser) {
      return this.currentUser;
    }
    return null;
  }

  get memberships() {
    return this.$store.getters.memberships.map((m) => {
      const isSubscribed =
        this.userMembership?.products.find((p) => p.productId === m.id) !==
        undefined;
      return {
        ...m,
        isSubscribed
      };
    });
  }

  get userMembership(): Subscription | undefined {
    return this.$store.getters.subscriptions.find(
      (s) => s.stripeCustomerId === this.user?.stripeCustomerId
    );
  }

  get errorMessage(): string | null {
    if (!this.user?.stripeCustomerId) {
      return "Something went wrong! Please try again!";
    } else if (this.cards.length + this.bankAccounts.length === 0) {
      if (this.selectedUser) {
        return "This user has no payment method on file.";
      }
      return "Please add a payment method before selecting membership";
    }
    return null;
  }

  get paymentMethodLink(): string | null {
    if (this.cards.length + this.bankAccounts.length === 0) {
      if (!this.selectedUser) {
        return "/profile/payments";
      }
    }
    return null;
  }

  subscribePath(
    m: Partial<Membership & { isSubscribed: boolean }>
  ): string | null {
    if (!m.isSubscribed) {
      if (this.selectedUser) {
        return `/users/membership/${this.selectedUser.uid}/subscribe/${m.id}`;
      }
      return `/profile/memberships/subscribe/${m.id}`;
    } else {
      if (this.selectedUser) {
        return `/users/membership/${this.selectedUser.uid}/unsubscribe/${m.id}`;
      }
      return `/profile/memberships/unsubscribe/${m.id}`;
    }
  }

  @WatchLoading()
  async finishSubscription() {
    await this.$store.dispatch(
      "getUserSubscription",
      this.user?.stripeCustomerId
    );
    if (this.selectedUser) {
      this.$router.replace(`/users/membership/${this.selectedUser.uid}`);
    } else {
      this.$router.replace("/profile/memberships");
    }
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getAllMemberships");
    await this.$store.dispatch(
      "getUserSubscription",
      this.user?.stripeCustomerId
    );
    await this.stripeClientLoaded();
  }

  async stripeClientLoaded() {
    if (
      this.user &&
      this.user.stripeCustomerId &&
      this.$stripeClient &&
      this.$store.getters.merchantInfo?.merchantId
    ) {
      this.cards = (
        await this.$stripeClient.get<Array<StripeCardPaymentMethod>>(
          "/paymentMethods",
          {
            params: {
              customer: this.user.stripeCustomerId,
              paymentMethod: "card"
            }
          }
        )
      ).data;
      this.bankAccounts = (
        await this.$stripeClient.get<Array<StripeBankPaymentMethod>>(
          "/paymentMethods",
          {
            params: {
              customer: this.user.stripeCustomerId,
              paymentMethod: "us_bank_account"
            }
          }
        )
      ).data;
    }
  }
}
